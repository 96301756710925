.text-topic {
  font-weight: bold;
  color: #0c4c9f;
}

.text-topic-2 {
  font-weight: bold;
  color: #1976d2;
}

.fs-2 {
  font-size: 20px;
}
.fs-3 {
  font-size: 30px;
}
.fs-4 {
  font-size: 40px;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right !important;
}

.text-white {
  color: white;
}

.text-red {
  color: #d45252;
}

.card-center-page {
  display: flex;
  align-items: center;
  justify-content: center;
}
p {
  margin-bottom: 0;
}
.font-w-bold {
  font-weight: bold;
}
.border-around {
  border: 1px solid #bbbbbb;
  border-radius: 5px;
}
.font-s-small {
  font-size: 12px;
  font-weight: bold;
}

.font-l-big {
  font-size: 17px;
  font-weight: bold;
}

.preset-active {
  background-color: #173c6d !important;
  p {
    color: white;
  }
  svg {
    color: #d8d8d8;
  }
}

.CircularProgressbar-text {
  font-size: 9px !important;
}

.MuiContainer-100 {
  max-width: 100% !important;
  padding: 0 !important;
}

.chart-panel {
  position: relative;
  .btn-downlod {
    position: absolute;
    top: 5px;
    right: 5px;
    display: none;
  }
  &:hover {
    .btn-downlod {
      display: block;
    }
  }
}

.MuiContainer-root {
  margin: 0 !important;
  padding: 0 !important;
}

.w-90 {
  width: 90%;
  margin: auto;
}

.MuiListItemIcon-root {
  //color: rgb(114 109 109 / 54%) !important;
  color: #8a8c8d !important;
}
.MuiListItemIcon-root {
  color: #8a8c8d !important;
}
