.login {
  .card-login {
    width: 40%;
  }
  .text-topic {
    font-size: 20px;
    font-weight: bold;
    color: #0c4c9f;
  }
  height: 100vh;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    linear-gradient(148.89deg, #0075ff 32.3%, #00c2ab 117.01%);
}

.img-logo {
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
}

.w-30-per {
  width: 30%;
}

@media (max-width: 450px) {
  .w-30-per {
    width: 100%;
  }
}