.b-g {
  background-color: #0c4c9f; /* For browsers that do not support gradients */
  background-image: linear-gradient(to right, #0c4c9f, #004d5e);
}

.pd-3-side {
  padding: 3rem 3rem 0rem 3rem;
}

.show-first {
  align-items: center;
  height: 450px;
}

.clickhere {
  .showme {
    opacity: 0;
    transform: translateY(100%);
    transition: transform 0.5s, opacity 0.5s;
  }
  .show-two {
    transform: translateY(50%);
    transition: transform 0.5s, opacity 0.5s;
  }
  .w-img {
    width: 250px;
    transition: transform .2s;
  }
}
.clickhere:hover {
  .showme {
    opacity: 1;
    transform: translateY(0);
  }
  .show-two {
    transform: translateY(0);
    transition: transform 0.5s, opacity 0.5s;
  }
  .w-img {
    transform: scale(1.5);
  }
}

.clickhere2 {
  .showme {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 0.5s, opacity 0.5s;
  }
  .show-two {
    transform: translateX(-53%);
    transition: transform 0.5s, opacity 0.5s;
  }
  .w-img {
    width: 300px;
    transition: transform .2s;
  }
}
.clickhere2:hover {
  .showme {
    opacity: 1;
    transform: translateX(15%);
  }
  .show-two {
    transform: translateX(13%);
    transition: transform 0.5s, opacity 0.5s;
  }
}

.clickhere3 {
  .showme {
    opacity: 0;
    transform: translateY(100%);
    transition: transform 0.5s, opacity 0.5s;
  }
  .show-two {
    transform: translateY(50%);
    transition: transform 0.5s, opacity 0.5s;
  }
  .w-img {
    width: 350px;
  }
}
.clickhere3:hover {
  .showme {
    opacity: 1;
    transform: translateY(0);
  }
  .show-two {
    transform: translateY(-18%);
    transition: transform 0.5s, opacity 0.5s;
  }
}

.clickhere4 {
  .showme {
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.5s, opacity 0.5s;
  }
  .show-two {
    transform: translateX(0%);
    transition: transform 0.5s, opacity 0.5s;
  }
  .w-img {
    width: 300px;
    transition: transform .2s;
  }
}
.clickhere4:hover {
  .showme {
    opacity: 1;
    transform: translateX(-40%);
  }
  .show-two {
    transform: translateX(-60%);
    transition: transform 0.5s, opacity 0.5s;
  }
}
