.button-save {
  background-color: #0c4c9f !important;
  border: #0c4c9f !important;
  color: white !important;
  font-weight: bold;
  width: 250px !important;
  margin-top: 15px !important;
  border-radius: 10px !important;
}

.button-save:hover {
  background-color: #003985 !important;
}

.file-upload {
  display: block;
  width: 100%;
  padding: 0.25rem 2rem 0.25rem 1rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  appearance: none;
}

.container-upload {
  display: flex;
  align-items: center;
}

.file-name-display {
  width: fit-content;
  white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
}

.button-uplode {
  background-color: rgba(125, 125, 125, 1) !important;
  padding: 15px !important;
  left: 40px;
  width: 150px;
  height: 25px;
  font-size: 15px;
  padding: 10px 10px;
  border-radius: 7px !important;
}
.rightIcon {
  margin-left: 8px;
}

.hiddenInput {
  display: none;
}

.font-select {
  padding: 0.45rem 2.25rem 0.45rem 0.75rem;
}

@media (max-width: 767px) {
  .button-uplode {
    justify-content: center;
  }
}

//tab2

.button-calibrate {
  width: 90%;
  height: 40px;
  background-color: #0c4c9f !important;
  border-radius: 5px;
  padding: 0rem 0rem 0rem 0rem;
}

.calibrate-progress {
  font-size: 14px;
  color: rgba(251, 211, 0, 1);
  margin-left: 2%;
  margin-right: 5%;
}

.calibrate-faSpinner {
  font-size: 15px;
  color: rgba(251, 211, 0, 1);
  margin-top: 1%;
}

.calibrate-succeed {
  font-size: 14px;
  color: #7abb35;
  margin-left: 2%;
  margin-right: 5%;
}

.calibrate-faCheckCircle {
  font-size: 15px;
  color: #7abb35;
  margin-top: 1%;
}

@media (max-width: 954px) {
  .d-flex.flex-row-reverse {
    justify-content: center;
    margin-top: 0.5rem;
  }
  .button-control {
    margin-top: 2rem;
  }
  .calibrate-progress {
    margin-top: 2rem;
  }
  .calibrate-faSpinner {
    margin-top: 2rem;
  }
}

.swal2-html-container {
  text-align: center !important;
}

.swal2-title {
  text-align: center !important;
}

.fo-fm {
  font-family: "Prompt", sans-serif !important;
}
