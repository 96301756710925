.modal-delete {
  .swal2-title {
    padding: 0.8em 1.4em 0;
    font-size: 1.3em;
    text-align: left;
  }

  .swal2-html-container {
    text-align: left;
  }
}

.swal2-actions {
  button {
    width: 165px;
  }
}