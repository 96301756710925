//.css-1ujykiq-MuiButtonBase-root-MuiTab-root.Mui-selected {
//  background-color: #1976d2 !important;
//  color: white !important;
//  font-weight: bold;
//}
.custom-tab-bg {
  background-color: #1976d2 !important;
  font-weight: bold;
  p {
    color: white !important;
  }
}
.custom-tab-text {
  color: #1976d2 !important;
  font-weight: bold !important;
}
.css-1gsv261 {
  border-bottom: unset !important;
}
.CircularProgressbar-path {
  stroke: #1976d2 !important;
}
.CircularProgressbar-text {
  fill: #494949 !important;
  font-size: 12px !important;
  font-weight: bold;
}

.MuiTabPanel-root {
  padding: unset !important;
}

::-webkit-scrollbar {
  display: none;
}
.MuiListItemText-root {
  .MuiListItemText-primary {
    color: #8a8c8d !important;
    font-weight: bold !important;
    font-family: "Prompt", serif !important;
  }
}

.MuiTypography-root {
  text-align: left !important;
  color: #ffffff !important;
}

.section-logrunning {
  p {
    font-size: 1rem;
    padding-left: 45px;
    color: #8a8c8d !important;
    font-weight: bold !important;
    font-family: "Prompt", serif !important;
  }
}

.MuiButtonBase-root-MuiListItemButton-root:hover {
  background-color: #d0e5ff !important;
}
.MuiButtonBase-root-MuiListItemButton-root {
  background-color: #e3efff !important;
}
.p-down {
  padding: 6px !important;
}
.MuiCardContent-root {
  padding-top: 6px !important;
}

.MuiCardHeader-root {
  background-color: #0c4c9f;
}
.vl {
  border-left: 1px solid #bbbbbb;
  height: 90%;
}
.circle {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: auto 5px auto 5px;
}
.div-in-card {
  height: 60px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.border-card {
  border: 1px solid #bbbbbb;
  border-radius: 5px;
}
